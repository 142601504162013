import * as React from 'react';
import { AntDConfigProvider } from '@sharefiledev/antd-config';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncRemediationPreferences } from './components';
import { setLogger } from './utils/logger/logger';

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:remediation-preferences-pilet',
		() => (
			<div data-testid="example-id">
				<AntDConfigProvider>
					<AsyncRemediationPreferences />
				</AntDConfigProvider>
			</div>
		)
	);
}
